import {
  Typography,
  Grid,
  Box,
  Fab,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import PageTransition from "./PageTransition";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/carousel.scss";
import "../styles/main.css";
import { useNavigate } from "react-router-dom";
import na5 from "../images/webp/img4.webp";
import na2 from "../images/webp/na2.webp";
import na3 from "../images/webp/na3.webp";
import na4 from "../images/webp/na4.webp";

const buttonContactUsSx = {
  mt: 2,
  backgroundColor: "#12a79d",
  color: "white",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#00948b",
  },
};

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export default function NavigationalAssessments() {
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  const navigate = useNavigate();

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <PageTransition>
      <div style={{ backgroundColor: "#2c2d2e", minHeight: "100vh" }}>
        <Container
          maxWidth="xl"
          sx={{
            color: "white",
            pt: { xs: 12, sm: 15, md: 15, lg: 9 },
            px: { xs: 2, sm: 10 },
            pb: 5,
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              marginBottom: { xs: "20px", sm: "30px" },
              paddingLeft: { xs: 0, sm: 3 },
              paddingBottom: "5px",
              borderBottom: "1px dashed rgba(255,255,255,.2)",
              fontSize: { xs: "2.5rem", sm: "3rem" },
              textAlign: { xs: "center", sm: "center", md: "center" },
            }}
          >
            Navigational Assessments.
            <Box
              component="img"
              sx={{
                position: "absolute",
                width: { xs: "64px", sm: "64px", md: "64px" },
              }}
              alt="Navigation Assessement Image 1"
              src={na3}
            />
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <Grid
              container
              spacing={2}
              sx={{
                py: { xs: 2, sm: 0, md: 0, lg: 0 },
                px: { xs: 2, sm: 1, md: 1, lg: 2 },
                textAlign: "center",
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  variant="h5"
                  color="white"
                  my={1}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left" },
                  }}
                >
                  <span className="subheading">
                    <span className="subheading">
                      What is a Navigational Assessment and why should companies
                      adopt them?
                    </span>
                  </span>
                </Typography>
                <Box
                  component="img"
                  sx={{
                    mt: { xs: 2, sm: 3 },
                    mb: { xs: 2, sm: 3 },
                    width: { xs: "90%", sm: "80%", md: "50%" },
                    mx: { xs: 1, sm: 3, md: 10 },
                    borderRadius: 2,
                  }}
                  alt="Navigation Assessement Image 2"
                  src={na5}
                />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                    fontWeight: "bold",
                  }}
                >
                  There are subtle differences between the terms audit and
                  assessment:
                </Typography>
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  • An audit will verify on board compliance with the Safety
                  Management System (SMS) and industry regulations.
                  <br />• An assessment will additionally observe and evaluate
                  the practices and skill-sets of the individuals and bridge
                  team to provide assurance of standards of navigation.
                </Typography>
                <br />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  High standards of navigation are fundamental for the safety of
                  vessels, crews, cargoes and for the protection of the
                  environment. While the Master is ultimately responsible for
                  the safety of navigation, the International Safety Management
                  (ISM) Code requires that companies set and maintain standards.
                  Whilst the International Maritime Organization (IMO) through
                  its ISM Code demands that navigational procedures are in
                  place, there is no requirement to conduct navigational
                  assessments. However, experience gained during external
                  reviews and discussions with companies/ship operators has
                  shown that navigational assessments are necessary to fully
                  assess the navigational safety culture and skills of
                  individuals and the effectiveness of the bridge team during
                  all stages of the vessel’s navigational passage. Hence, the
                  purpose of a navigational assessment is to identify poor
                  practices, to continuously improve navigational standards to
                  ensure safe and effective voyages and to assure companies that
                  high standards of navigation and watchkeeping are being
                  maintained.
                </Typography>
                <br />
                <Box
                  component="img"
                  sx={{
                    mt: { xs: 0, sm: 0 },
                    mb: { xs: 2, sm: 3 },
                    width: { xs: "90%", sm: "80%", md: "50%" },
                    mx: { xs: 1, sm: 3, md: 10 },
                    borderRadius: 2,
                  }}
                  alt="Navigation Assessement Image 2"
                  src={na2}
                />
                <br />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  To fully meet the objectives of a navigational assessment,
                  both the technical and non-technical skills of bridge team
                  members are evaluated. Technical (hard) skills are knowledge
                  of regulatory and company requirements and are competency
                  based. They are assessed against the level of compliance with
                  regulations and company procedures and the application and use
                  of equipment in aspects of navigation, including company
                  policies and procedures. Non-technical (soft) skills are
                  related to human factors and can be evaluated by observing the
                  bridge team at work, measuring their ability to work and
                  communicate as a team and their reaction to evolving
                  navigational situations and challenges. Navigational
                  assessments are further analyzed to identify any trends which
                  may be used to further identify areas for improvement, such as
                  embedding and reinforcing a safety culture, or for updating
                  specific company requirements. The results of the analysis
                  should be used to update a company’s policies, procedures and
                  training through the Safety Management System.
                </Typography>
                <br />
                <Box
                  component="img"
                  sx={{
                    backgroundColor: "white",
                    mt: { xs: 2, sm: 3 },
                    mb: { xs: 0, sm: 1 },
                    width: { xs: "30%", sm: "20%", md: "15%" },
                    mx: { xs: 1, sm: 3, md: 10 },
                    borderRadius: 2,
                  }}
                  alt="Navigation Assessement Image 2"
                  src={na4}
                />
                <br />
                <br />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "center",
                    px: { xs: 1, sm: 1 },
                    fontWeight: "bold",
                  }}
                >
                  WOULD YOU LIKE TO ANALYZE THE LEVEL OF YOUR FLEET COMPLIANCE
                  AGAINST THE INDUSTRY STANDARD?
                  <br />
                  <br />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid xs={12} sm={12} md={12} lg={8}>
                      <Card
                        sx={{
                          py: 4,
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6">
                            SEND US AN ENQUIRY TO SCHEDULE PERSONAL MEETING
                          </Typography>
                          <Typography variant="h6">
                            AND DISCUSS YOUR BUSINESS NEEDS!
                          </Typography>
                          <Button
                            variant="contained"
                            sx={buttonContactUsSx}
                            size="large"
                            onClick={() => navigate("/contact")}
                          >
                            Contact Us
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
      </div>
      <Fab
        size="medium"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          backgroundColor: "#138c84",
          "&:hover": {
            bgcolor: "#12a79d",
          },
        }}
        onClick={() => navigate("/contact")}
      >
        <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
      </Fab>
    </PageTransition>
  );
}
