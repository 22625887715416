import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import ListWithItems from "./NavigationItems";
import Home from "./Home";
import AboutUs from "./AboutUs";
import { Route, Routes, useLocation } from "react-router-dom";
import OurServices from "./OurServices";
import Contact from "./Contact";
import SocialMediaNavigation from "./SocialMediaNavigation";
import Logo from "./Logo";
import { AnimatePresence, motion } from "framer-motion";
import { BeatLoader } from "react-spinners";
import "../styles/spinner.css";
import { CssBaseline, AppBar, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PrevettingInspections from "./PrevettingInspections";
import NavigationalAssessments from "./NavigationalAssessments";
import OurPartners from "./OurPartners";

const drawerWidth: number = 240;

const mdTheme = createTheme();

function MainContent() {
  const [showSpinner, setShowSpinner] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 2000); // Set the duration (in milliseconds) for displaying the spinner

    return () => clearTimeout(timer);
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { lg: `${drawerWidth}px` },
          display: { lg: "none", md: "block", sm: "block", xs: "block" },
          backgroundColor: "#087c75",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Menu
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#6b6c6d",
          flex: "0 0 100%",
          flexGrow: "1",
        }}
      >
        <AnimatePresence mode="wait">
          {showSpinner ? (
            <motion.div
              className="spinner-container"
              key="in"
              transition={{
                ease: "easeInOut",
                duration: "0.4",
              }}
              initial={{
                width: 0,
              }}
              animate={{
                width: "100%",
              }}
              exit={{
                width: "100%",
                x: window.innerWidth,
              }}
            >
              <BeatLoader color="#12a79d" />
            </motion.div>
          ) : (
            <>
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                PaperProps={{
                  sx: {
                    width: "30%",
                    backgroundColor: "#17181b",
                    color: "#f0f0f1",
                    height: "100vh",
                  },
                }}
                sx={{
                  display: { xs: "block", md: "block", lg: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                <Toolbar
                  variant="dense"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    px: [0],
                  }}
                >
                  <Logo />
                </Toolbar>
                <ListWithItems
                  handleDrawerClose={handleDrawerClose}
                ></ListWithItems>
                <SocialMediaNavigation />
              </Drawer>
              <Drawer
                variant="permanent"
                open
                PaperProps={{
                  sx: {
                    position: "sticky !important",
                    width: "30%",
                    backgroundColor: "#17181b",
                    color: "#f0f0f1",
                    height: "100vh",
                    top: "0",
                    bottom: "0",
                    left: "0",
                  },
                }}
                sx={{
                  display: { xs: "none", md: "none", lg: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                <Toolbar
                  variant="dense"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    px: [0],
                  }}
                >
                  <Logo />
                </Toolbar>
                <ListWithItems />
                <SocialMediaNavigation />
              </Drawer>
              <Box sx={{ width: "70%", flexGrow: "1" }}>
                <AnimatePresence mode="wait">
                  <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<Home />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/our-partners" element={<OurPartners />} />
                    <Route path="/our-services" element={<OurServices />} />
                    <Route
                      path="/navigational-assessments"
                      element={<NavigationalAssessments />}
                    />
                    <Route
                      path="/pre-vetting-inspections"
                      element={<PrevettingInspections />}
                    />
                    <Route path="/contact" element={<Contact />} />
                  </Routes>
                </AnimatePresence>
              </Box>
            </>
          )}
        </AnimatePresence>
      </Box>
    </ThemeProvider>
  );
}

export default function Main() {
  return <MainContent />;
}
