import * as React from "react";
import Main from "./Components/Main";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/app.css";
import ScrollToTop from "./Components/ScrollToTop";

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <Main />
    </Router>
  );
}
