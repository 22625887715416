import { Typography, Grid, Fab, Stack, Box } from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import PageTransition from "./PageTransition";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/carousel.scss";
import "../styles/main.css";
import { useNavigate } from "react-router-dom";
import MacosnarCorporation from "../images/webp/partner1.webp";
import PMACertification from "../images/webp/partner2.webp";
import PanamaShipRegistry from "../images/webp/partner4.webp";
import TheLiberianRegistry from "../images/webp/TLR.webp";
import IAMCS from "../images/webp/IAMCS.webp";
import VanCannSolutions from "../images/webp/VanCann.webp";
import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function OurPartners() {
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const navigate = useNavigate();

  return (
    <PageTransition>
      <div style={{ backgroundColor: "#2c2d2e", minHeight: "100vh" }}>
        <Container
          maxWidth="xl"
          sx={{
            color: "white",
            pt: { xs: 12, sm: 15, md: 15, lg: 9 },
            px: { xs: 2, sm: 10 },
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              marginBottom: { xs: "20px", sm: "30px" },
              paddingLeft: { xs: 0, sm: 3 },
              paddingBottom: "5px",
              borderBottom: "1px dashed rgba(255,255,255,.2)",
              fontSize: { xs: "2.5rem", sm: "3rem" },
              textAlign: { xs: "center", sm: "center" },
            }}
          >
            Our Partners.
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={12} md={10} lg={8}>
                <Box sx={{ width: "100%" }}>
                  <Stack spacing={4} sx={{ my: 4 }}>
                    <Item>
                      <Typography
                        variant="h6"
                        color="white"
                        mt={2}
                        sx={{
                          textAlign: {
                            xs: "center",
                            sm: "center",
                            md: "center",
                          },
                        }}
                      >
                        <span className="subheading">
                          <span className="subheading">
                            1. The Liberian Registry
                          </span>
                        </span>
                        <Box>
                          <a href="https://www.liscr.com/" target="_blank">
                            <LanguageIcon
                              sx={{ color: "white", mr: 1, fontSize: "28px" }}
                            />
                          </a>
                          <a
                            href="https://www.linkedin.com/company/liscr/"
                            target="_blank"
                          >
                            <LinkedInIcon
                              sx={{ color: "white", mr: 1, fontSize: "28px" }}
                            />
                          </a>
                        </Box>
                      </Typography>
                      <a href="https://www.liscr.com/" target="_blank">
                        <Box
                          sx={{
                            py: { xs: 3, sm: 3, md: 3, lg: 3 },
                            px: { xs: 3, sm: 3, md: 3, lg: 3 },
                            width: {
                              xs: "90%",
                              sm: "90%",
                              md: "70%",
                              lg: "70%",
                            },
                          }}
                          component="img"
                          alt="TheLiberianRegistryImage"
                          src={TheLiberianRegistry}
                        />
                      </a>
                    </Item>
                    <Item>
                      <Typography
                        variant="h6"
                        color="white"
                        mt={2}
                        sx={{
                          textAlign: {
                            xs: "center",
                            sm: "center",
                            md: "center",
                          },
                        }}
                      >
                        <span className="subheading">
                          <span className="subheading">
                            2. Panama Ship Registry
                          </span>
                        </span>
                        <Box>
                          <a
                            href="https://www.panamashipregistry.com/"
                            target="_blank"
                          >
                            <LanguageIcon
                              sx={{ color: "white", mr: 1, fontSize: "28px" }}
                            />
                          </a>
                          <a
                            href="https://www.linkedin.com/company/autoridad-maritima-de-panama/"
                            target="_blank"
                          >
                            <LinkedInIcon
                              sx={{ color: "white", mr: 1, fontSize: "28px" }}
                            />
                          </a>
                        </Box>
                      </Typography>
                      <a
                        href="https://www.panamashipregistry.com/"
                        target="_blank"
                      >
                        <Box
                          sx={{
                            py: { xs: 3, sm: 3, md: 3, lg: 3 },
                            px: { xs: 3, sm: 3, md: 3, lg: 3 },
                            width: {
                              xs: "65%",
                              sm: "45%",
                              md: "40%",
                              lg: "45%",
                              xl: "35%",
                            },
                          }}
                          component="img"
                          alt="PanamaShipRegistryImage"
                          src={PanamaShipRegistry}
                        />
                      </a>
                    </Item>
                    <Item>
                      <Typography
                        variant="h6"
                        color="white"
                        mt={2}
                        sx={{
                          textAlign: {
                            xs: "center",
                            sm: "center",
                            md: "center",
                          },
                        }}
                      >
                        <span className="subheading">
                          <span className="subheading">
                            3. Macosnar Corporation – Marine Consultants,
                            Surveys and Naval Register
                          </span>
                          <Box>
                            <a href="https://macosnar.com/" target="_blank">
                              <LanguageIcon
                                sx={{ color: "white", mr: 1, fontSize: "28px" }}
                              />
                            </a>
                            <a
                              href="https://www.linkedin.com/company/macosnar-corporation/"
                              target="_blank"
                            >
                              <LinkedInIcon
                                sx={{ color: "white", mr: 1, fontSize: "28px" }}
                              />
                            </a>
                          </Box>
                        </span>
                      </Typography>
                      <a href="https://macosnar.com/" target="_blank">
                        <Box
                          sx={{
                            py: { xs: 3, sm: 3, md: 3, lg: 3 },
                            px: { xs: 3, sm: 3, md: 3, lg: 3 },
                            width: {
                              xs: "65%",
                              sm: "45%",
                              md: "40%",
                              lg: "45%",
                              xl: "35%",
                            },
                          }}
                          component="img"
                          alt="MacosnarCorporationImage"
                          src={MacosnarCorporation}
                        />
                      </a>
                    </Item>
                    <Item>
                      <Typography
                        variant="h6"
                        color="white"
                        mt={2}
                        sx={{
                          textAlign: {
                            xs: "center",
                            sm: "center",
                            md: "center",
                          },
                        }}
                      >
                        <span className="subheading">
                          <span className="subheading">
                            4. International Association of Marine Consultants
                            and Surveyors (IAMCS)
                          </span>
                        </span>
                        <Box>
                          <a href="https://iamcs.org.uk/" target="_blank">
                            <LanguageIcon
                              sx={{ color: "white", mr: 1, fontSize: "28px" }}
                            />
                          </a>
                          <a
                            href="https://www.linkedin.com/school/maritime-academy/"
                            target="_blank"
                          >
                            <LinkedInIcon
                              sx={{ color: "white", mr: 1, fontSize: "28px" }}
                            />
                          </a>
                        </Box>
                      </Typography>
                      <a href="https://iamcs.org.uk/" target="_blank">
                        <Box
                          sx={{
                            py: { xs: 3, sm: 3, md: 3, lg: 3 },
                            px: { xs: 3, sm: 3, md: 3, lg: 3 },
                            width: {
                              xs: "65%",
                              sm: "45%",
                              md: "40%",
                              lg: "45%",
                              xl: "35%",
                            },
                          }}
                          component="img"
                          alt="IAMCSImage"
                          src={IAMCS}
                        />
                      </a>
                    </Item>
                    <Item>
                      <Typography
                        variant="h6"
                        color="white"
                        mt={2}
                        sx={{
                          textAlign: {
                            xs: "center",
                            sm: "center",
                            md: "center",
                          },
                        }}
                      >
                        <span className="subheading">
                          <span className="subheading">
                            5. Vessel registration, surveys and certification
                          </span>
                        </span>
                        <Box>
                          <a
                            href="https://www.pmacertification.com/"
                            target="_blank"
                          >
                            <LanguageIcon
                              sx={{ color: "white", mr: 1, fontSize: "28px" }}
                            />
                          </a>
                          <a
                            href="https://www.linkedin.com/company/pma-certification/"
                            target="_blank"
                          >
                            <LinkedInIcon
                              sx={{ color: "white", mr: 1, fontSize: "28px" }}
                            />
                          </a>
                        </Box>
                      </Typography>
                      <a
                        href="https://www.pmacertification.com/"
                        target="_blank"
                      >
                        <Box
                          sx={{
                            py: { xs: 3, sm: 3, md: 3, lg: 3 },
                            px: { xs: 3, sm: 3, md: 3, lg: 3 },
                            width: {
                              xs: "65%",
                              sm: "45%",
                              md: "40%",
                              lg: "45%",
                              xl: "35%",
                            },
                          }}
                          component="img"
                          alt="PMACertificationImage"
                          src={PMACertification}
                        />
                      </a>
                    </Item>
                    <Item>
                      <Typography
                        variant="h6"
                        color="white"
                        mt={2}
                        sx={{
                          textAlign: {
                            xs: "center",
                            sm: "center",
                            md: "center",
                          },
                        }}
                      >
                        <span className="subheading">
                          <span className="subheading">
                            6. Marine and technical consulting for
                            shipmanagement &amp; shipbuilding
                          </span>
                        </span>
                        <Box>
                          <a
                            href="https://www.vancann-solutions.com/"
                            target="_blank"
                          >
                            <LanguageIcon
                              sx={{ color: "white", mr: 1, fontSize: "28px" }}
                            />
                          </a>
                          <a
                            href="https://www.linkedin.com/in/ron-van-cann-280ba41a2/"
                            target="_blank"
                          >
                            <LinkedInIcon
                              sx={{ color: "white", mr: 1, fontSize: "28px" }}
                            />
                          </a>
                        </Box>
                      </Typography>
                      <a
                        href="https://www.vancann-solutions.com/"
                        target="_blank"
                      >
                        <Box
                          sx={{
                            py: { xs: 3, sm: 3, md: 3, lg: 3 },
                            px: { xs: 3, sm: 3, md: 3, lg: 3 },
                            width: {
                              xs: "65%",
                              sm: "45%",
                              md: "40%",
                              lg: "45%",
                              xl: "35%",
                            },
                          }}
                          component="img"
                          alt="VanCannSolutionsImage"
                          src={VanCannSolutions}
                        />
                      </a>
                    </Item>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
        <Fab
          size="medium"
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
            backgroundColor: "#138c84",
            "&:hover": {
              bgcolor: "#12a79d",
            },
          }}
          onClick={() => navigate("/contact")}
        >
          <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
        </Fab>
      </div>
    </PageTransition>
  );
}
