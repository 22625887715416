import Box from "@mui/material/Box";
import * as React from "react";
import logo from "../images/webp/logo-original.webp";

export default function Logo() {
  return (
    <Box
      component="img"
      sx={{
        maxWidth: "100%",
        maxHeight: "100%",
        display: "block",
        p: 1,
      }}
      alt="logo"
      src={logo}
    ></Box>
  );
}
