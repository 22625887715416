import * as React from "react";
import { motion } from "framer-motion";

const PageTransition = ({ children }: any) => {
  const widthAnimation = {
    initial: {
      width: 0,
    },
    animate: {
      width: "100%",
    },
    exit: {
      width: "100%",
      x: window.innerWidth,
    },
  };

  return (
    <motion.div
      transition={{
        ease: "easeInOut",
        duration: "0.4",
      }}
      variants={widthAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {children}
    </motion.div>
  );
};

export default PageTransition;
