import {
  Typography,
  Grid,
  Box,
  Button,
  Fab,
  Card,
  CardContent,
} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import PageTransition from "./PageTransition";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/carousel.scss";
import "../styles/main.css";
import { useNavigate } from "react-router-dom";
import na5 from "../images/webp/na5.webp";
import pvi2 from "../images/webp/pvi2.webp";
import pvi3 from "../images/webp/pvi3.webp";

const buttonContactUsSx = {
  my: 2,
  color: "white",
  backgroundColor: "#12a79d",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#00948b",
  },
};

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export default function NavigationalAssessments() {
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  const navigate = useNavigate();

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <PageTransition>
      <div style={{ backgroundColor: "#2c2d2e", minHeight: "100vh" }}>
        <Container
          maxWidth="xl"
          sx={{
            color: "white",
            pt: { xs: 12, sm: 15, md: 15, lg: 9 },
            px: { xs: 2, sm: 10 },
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              marginBottom: { xs: "20px", sm: "30px" },
              paddingLeft: { xs: 0, sm: 3 },
              paddingBottom: "5px",
              borderBottom: "1px dashed rgba(255,255,255,.2)",
              fontSize: { xs: "2.5rem", sm: "3rem" },
              textAlign: { xs: "center", sm: "center" },
            }}
          >
            Pre-vetting Inspections.
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <Grid
              container
              spacing={2}
              sx={{
                py: { xs: 2, sm: 0, md: 0, lg: 0 },
                px: { xs: 2, sm: 1, md: 1, lg: 2 },
                textAlign: "center",
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  variant="h5"
                  color="white"
                  my={1}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left" },
                  }}
                >
                  <span className="subheading">
                    <span className="subheading">The Vetting Process</span>
                  </span>
                </Typography>
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  Traders all around the world buy and sell commodities like
                  oil, gas, chemicals, iron ore etc, which needs to be
                  transported from one place to another. In order to be able to
                  do this, charterers need to find suitable ships to transport
                  their cargo. A suitable ship is one which fits the requirement
                  with regard to size, equipment, availability and special
                  requirement of the load/discharge terminal. When charterers
                  hire vessels to carry their assets (the cargo) they accept a
                  certain level of risk. It is only prudent that the level of
                  risk is assessed prior taking on the vessel for the business.
                  Prudent assurance process requires a physical verification of
                  the compliance level against the industry standards as set by
                  Oil Companies International Marine Forum (OCIMF), Chemical
                  Distribution Institute (CDI) and Rightship. The results of
                  vetting inspection are submitted to dedicated databases hosted
                  by these companies where any interested party may buy the
                  report at a small cost. On each occasion that a vessel is
                  considered for business the marine assurance process called
                  the ‘vetting’ is carried out using all available information
                  regarding the vessel and ship operator. The information
                  normally refers to the following:
                </Typography>
                <br />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  • Port State Control inspection reports (available on MOU
                  website)
                  <br />
                  • Physical Vetting Inspection Reports
                  <br />
                  • Manager’s performance ratings
                  <br />
                  • Media reporting (eg. Lloyd's Casualty reporting, Tradewinds
                  etc)
                  <br />
                  • Audits of the management company (for tanker operators the
                  TMSA (Tanker Managers Self-Assessments), and the equivalent in
                  the dry-bulk sector DBMS (Dry Bulk Management Standard)).
                  <br />
                </Typography>
                <br />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  Based on the findings from these assessments the vessels are
                  assigned a certain risk level. This determines the suitability
                  of the vessel for employment. Vessels offering a low-risk
                  rating are considered good for business.{" "}
                </Typography>
                <br />
                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                    fontWeight: "bold",
                  }}
                >
                  Putting focus on pre-vetting inspections
                </Typography>
                <Box
                  component="img"
                  sx={{
                    mt: { xs: 2, sm: 3 },
                    mb: { xs: 2, sm: 3 },
                    mx: { xs: 1, sm: 1, md: 3 },
                    width: { xs: "90%", sm: "80%", md: "40%" },
                    borderRadius: 2,
                  }}
                  alt="Navigation Assessement Image 2"
                  src={pvi3}
                />
                <Box
                  component="img"
                  sx={{
                    mt: { xs: 2, sm: 3 },
                    mb: { xs: 2, sm: 3 },
                    mx: { xs: 1, sm: 1, md: 3 },
                    width: { xs: "90%", sm: "80%", md: "40%" },
                    borderRadius: 2,
                  }}
                  alt="Navigation Assessement Image 2"
                  src={pvi2}
                />

                <Typography
                  color="white"
                  sx={{
                    textAlign: "justify",
                    px: { xs: 1, sm: 1 },
                  }}
                >
                  Within our team we have experienced and still active vetting
                  inspectors who can support and guide your shipboard staff in
                  preparing themselves for the upcoming vetting inspection. An
                  onboard inspection can only be successful if the ship has been
                  prepared for the inspection. For this reason, a pre-vetting
                  inspection is of essential importance in maintaining the
                  vessel’s rating thus being commercially eligible for trading.
                </Typography>
                <Box
                  component="img"
                  sx={{
                    mt: { xs: 2, sm: 3 },
                    mb: { xs: 2, sm: 3 },
                    width: { xs: "90%", sm: "80%", md: "50%" },
                    mx: { xs: 1, sm: 3, md: 10 },
                    borderRadius: 2,
                  }}
                  alt="PreVetting Image 5"
                  src={na5}
                />
                <Typography
                  color="white"
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    px: { xs: 1, sm: 1 },
                    fontWeight: "bold",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Grid xs={12} sm={12} md={12} lg={6}>
                      <Card
                        sx={{
                          pt: 2,
                          mb: 5,
                        }}
                      >
                        <CardContent>
                          BOOK HERE YOUR PRE-VETTING INSPECTION
                          <br />
                          <Button
                            variant="contained"
                            sx={buttonContactUsSx}
                            size="large"
                            onClick={() => navigate("/contact")}
                          >
                            Contact Us
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
      </div>
      <Fab
        size="medium"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          backgroundColor: "#138c84",
          "&:hover": {
            bgcolor: "#12a79d",
          },
        }}
        onClick={() => navigate("/contact")}
      >
        <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
      </Fab>
    </PageTransition>
  );
}
