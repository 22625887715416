import {
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  Card,
  CardContent,
  Fab,
} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import PageTransition from "./PageTransition";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../styles/main.css";
import {
  faCompass,
  faEnvelope,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const buttonSx = {
  color: "white",
  backgroundColor: "#12a79d",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#00948b",
  },
  m: 2,
  textAlign: "center",
};

const buttonCompanyButtonSx = {
  color: "white",
  borderColor: "#12a79d",
  "&:hover": {
    bgcolor: "#12a79d",
  },
  m: 2,
  textAlign: "center",
};

export default function OurServices() {
  const navigate = useNavigate();
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  return (
    <PageTransition>
      <div style={{ backgroundColor: "#2c2d2e", minHeight: "100vh" }}>
        <Container
          maxWidth="xl"
          sx={{
            color: "white",
            pt: { xs: 12, sm: 15, md: 15, lg: 9 },
            px: { xs: 2, sm: 10 },
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              marginBottom: { xs: "20px", sm: "30px" },
              paddingLeft: { xs: 0, sm: 3 },
              paddingBottom: "5px",
              borderBottom: "1px dashed rgba(255,255,255,.2)",
              fontSize: { xs: "2.5rem", sm: "3rem" },
              textAlign: { xs: "center", sm: "center", md: "center" },
            }}
          >
            Our Services.
            <Button
              variant="outlined"
              sx={buttonCompanyButtonSx}
              href={
                "https://orion-vetting.com/files/ORION_VETTING_PORTRAIT.pdf"
              }
              target="_blank"
            >
              Download Company Portrait
            </Button>
          </Typography>
          <ThemeProvider theme={darkTheme}>
            <Grid container spacing={5} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={12} md={7}>
                <Typography
                  variant="h5"
                  color="white"
                  my={1}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left" },
                  }}
                >
                  <span className="subheading">
                    <span className="subheading">
                      Marine Assurance & Inspections
                    </span>
                  </span>
                </Typography>
                <Typography color="white"></Typography>
                <Box sx={{ color: "white" }}>
                  <List>
                    <ListItem>
                      <ListItemText primary="• Tanker pre-vetting marine inspections (SIRE & CDI)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Dry bulk pre-vetting marine inspections (Rightship)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Flag state inspections" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• CPD accredited marine surveys" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• P&I surveys" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Audits for standards ISM, ISPS, MLC, ISO" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Navigational Assessments" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Dry Bulk Management Standard compliance audits" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Implementation of Integrated Management Systems (ISO 9001, 14001, 45001)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Marine incident investigation" />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Typography
                  variant="h5"
                  color="white"
                  my={1}
                  sx={{
                    textAlign: { xs: "center", sm: "center", md: "left" },
                  }}
                >
                  <span className="subheading">
                    <span className="subheading">Technical Services</span>
                  </span>
                </Typography>
                <Box sx={{ color: "white" }}>
                  <List>
                    <ListItem>
                      <ListItemText primary="• Pre-purchase vessel inspections" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Dry docking supervision" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• New building supervision" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Retrofits & special projects" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Technical surveys (tankers & dry bulk)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Shipowner representation" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="• Technical support & consulting" />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={8}
              sx={{
                justifyContent: "center",
                px: { xs: 3, sm: 3, md: 11 },
                pb: 10,
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  textAlign: "center",
                  mt: { xs: 1, sm: 1, md: 3 },
                }}
              >
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{
                        textAlign: "center",
                        my: 1,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCompass}
                        size="xl"
                        color="#12a79d"
                      />
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontWeight: "800",
                      }}
                    >
                      Navigational Assessments
                    </Typography>
                    <Button
                      variant="contained"
                      sx={buttonSx}
                      size="large"
                      onClick={() => navigate("/navigational-assessments")}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  textAlign: "center",
                  mt: { xs: 0, sm: 0, md: 3 },
                }}
              >
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{
                        textAlign: "center",
                        my: 1,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faListCheck}
                        size="xl"
                        color="#12a79d"
                      />
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontWeight: "800",
                      }}
                    >
                      Pre-vetting Inspections
                    </Typography>
                    <Button
                      variant="contained"
                      sx={buttonSx}
                      size="large"
                      onClick={() => navigate("/pre-vetting-inspections")}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
      </div>
      <Fab
        size="medium"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          backgroundColor: "#138c84",
          "&:hover": {
            bgcolor: "#12a79d",
          },
        }}
        onClick={() => navigate("/contact")}
      >
        <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
      </Fab>
    </PageTransition>
  );
}
