import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBuilding,
  faHandshake,
  faEnvelope,
  faBriefcase
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse } from "@mui/material";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { faCompass } from "@fortawesome/free-regular-svg-icons";

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export const NavigationItems = (props: any) => {
  const [buttonTimeout, setButtonTimeout] = React.useState<boolean>(false);
  const [windowSize, setWindowSize] = React.useState(getWindowSize());
  const [open, setOpenDrawer] = React.useState(true);

  const handleClick = () => {
    setOpenDrawer(!open);
  };

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const styles = {
    minWidth: "30px",
    iconColor: "#f0f0f1",
  };

  const routes = {
    home: {
      to: "/",
      name: "Home",
      sx: { minWidth: styles.minWidth },
      icon: faHouse,
      style: { color: styles.iconColor },
      nested: false,
      submenus: [],
      key: "home",
    },
    aboutus: {
      to: "/about-us",
      name: "About Us",
      sx: { minWidth: styles.minWidth },
      icon: faBuilding,
      style: { color: styles.iconColor },
      nested: false,
      submenus: [],
      key: "au",
    },
    ourpartners: {
      to: "/our-partners",
      name: "Our Partners",
      sx: { minWidth: styles.minWidth },
      icon: faHandshake,
      style: { color: styles.iconColor },
      nested: false,
      submenus: [],
      key: "au",
    },
    ourservices: {
      to: "/our-services",
      name: "Our Services",
      sx: { minWidth: styles.minWidth },
      icon: faBriefcase,
      style: { color: styles.iconColor },
      nested: true,
      submenus: [
        {
          name: "Navigational Assessments",
          submenuIcon: faCompass,
          to: "/navigational-assessments",
          key: "na",
        },
        {
          name: "Pre-vetting Inspections",
          submenuIcon: faListCheck,
          to: "/pre-vetting-inspections",
          key: "pvi",
        },
      ],
      key: "os",
    },
    contact: {
      to: "/contact",
      name: "Contact",
      sx: { minWidth: styles.minWidth },
      icon: faEnvelope,
      style: { color: styles.iconColor },
      nested: false,
      submenus: [],
      key: "contact",
    },
  };

  const { pathname } = useLocation();

  return (
    <List component="nav">
      <React.Fragment>
        {Object.values(routes).map((route) => {
          return (
            <>
              <ListItemButton
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#12a79dcf",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "#12a79dcf",
                  },
                  "&.Mui-disabled": {
                    opacity: "1",
                  },
                }}
                selected={route.to === pathname}
                component={Link}
                to={route.to}
                key={route.key}
                onClick={() => {
                  if (windowSize.innerWidth < 1200) {
                    props.handleDrawerClose(false);
                  }
                  setButtonTimeout(true);
                  setTimeout(() => {
                    setButtonTimeout(false);
                  }, 400);
                }}
                disabled={buttonTimeout}
              >
                <ListItemIcon sx={route.sx}>
                  <FontAwesomeIcon icon={route.icon} style={route.style} />
                </ListItemIcon>
                <ListItemText primary={route.name} />
                {route.nested ? (
                  open ? (
                    <ExpandLess onClick={handleClick} />
                  ) : (
                    <ExpandMore onClick={handleClick} />
                  )
                ) : null}
              </ListItemButton>
              {route.nested ? (
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {route.submenus.length > 0 &&
                      route.submenus.map((submenu: any) => {
                        return (
                          <ListItemButton
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: "#12a79dcf",
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: "#12a79dcf",
                              },
                              "&.Mui-disabled": {
                                opacity: "1",
                              },
                              pl: 4,
                            }}
                            selected={submenu.to === pathname}
                            component={Link}
                            to={submenu.to}
                            key={submenu.key}
                            onClick={() => {
                              if (windowSize.innerWidth < 1200) {
                                props.handleDrawerClose(false);
                              }
                              setButtonTimeout(true);
                              setTimeout(() => {
                                setButtonTimeout(false);
                              }, 400);
                            }}
                            disabled={buttonTimeout}
                          >
                            <ListItemIcon sx={route.sx}>
                              <FontAwesomeIcon
                                icon={submenu.submenuIcon}
                                style={route.style}
                              />
                            </ListItemIcon>
                            <ListItemText primary={submenu.name} />
                          </ListItemButton>
                        );
                      })}
                  </List>
                </Collapse>
              ) : null}
            </>
          );
        })}
      </React.Fragment>
    </List>
  );
};

export default NavigationItems;
